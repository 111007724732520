.artists-loadmore-list {
  min-height: 350px;
}

.artists-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}
.artists-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
